@for (pdiDTO of data().PDIs; track pdiDTO) {
  @if (!pdiDTO.finishAnswering) {
    <div class="row">
      <a
        class="text-body pointer"
      [routerLink]="[
        '/pdi',
        data().member.idUser,
        string_to_slug(data().member.firstName + '-' + data().member.lastName),
        pdiDTO.idCampaign,
        string_to_slug(pdiDTO.campaignName | ms)
      ]"
        >
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 p-2 animate__animated animate__fadeInLeft">
          <div class="row g-1 bg-white border rounded-3 p-2">
            <div class="col-auto d-flex align-items-center">
              <i class="fa-light fa-graduation-cap fa-2x text-primary p-2 align-icon"></i>
            </div>
            <div class="col my-fs-4 my-fs-lg-3 d-flex flex-column justify-content-center">
              <div class="row">
                <div class="col-auto">
                  <b>{{ pdiDTO.campaignName | ms }}</b>
                </div>
              </div>
              @if (pdiDTO.draft) {
                <div class="row">
                  <div class="col-auto text-success">
                    <i class="fa-light fa-clipboard me-1"></i>
                    {{ 'pdi.card.En brouillon, à envoyer avant le' | translate }}
                    <span class="fw-bold" [class.text-danger]="verifWarningPDIEndDate(pdiDTO)">{{ pdiDTO.endDate | localizedDate }}</span>
                    @if (verifWarningPDIEndDate(pdiDTO)) {
                      <i class="fa-light fa-exclamation-triangle text-danger ms-2"></i>
                    }
                  </div>
                </div>
                @if (pdiDTO.idUserManager !== idUserLogged) {
                  <div class="row">
                    <div class="col-auto text-info">
                      <i class="fa-light fa-user me-1"></i>
                      {{ 'pdi.card.Commencé par:' | translate }} {{ pdiDTO.manager }}
                    </div>
                  </div>
                }
              }
              <div class="row">
                @if (!pdiDTO.draft) {
                  <div class="col">
                    <small>{{ 'pdi.card.Fin le' | translate }} {{ pdiDTO.endDate | localizedDate }}</small>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  }
}
