@if (hasAnyTagsToShow()) {
  <div class="container-fluid bg-white rounded-3 border pb-3">
    <div class="row">
      <hiji-tag-radar-chart [data]="radarData"></hiji-tag-radar-chart>
    </div>
    <div class="row justify-content-around">
      <div class="col-auto">
        <ic2-switch size="small" [(ngModel)]="showAutoObservations" (ngModelChange)="buildData()"></ic2-switch>
        <span class="ms-2 fw-bold hiji-text-auto-observation">{{ 'visualize.survey-chart.Mon observation' | translate }}</span>
      </div>
      @if (data.withSurveyResultsAvailable && !isInFuture(data.surveyResultsAvailableDate)) {
        <div class="col-auto">
          <ic2-switch size="small" [(ngModel)]="showOthers" (ngModelChange)="buildData()"></ic2-switch>
          <span class="ms-2 fw-bold hiji-text-manager">{{ 'visualize.survey-chart.Ensemble des collaborateurs' | translate }} (n={{ data.nbOtherStats }})</span>
        </div>
      }
      @if (data.withSurveyResultsAvailable && !isInFuture(data.surveyResultsAvailableDate) && data.withEnseigneResults) {
        <div class="col-auto">
          <ic2-switch size="small" [(ngModel)]="showEnseigne" (ngModelChange)="buildData()" [disabled]="data.nbEnseigneStats < data.nbMiniUsersStats"></ic2-switch>
          <span class="ms-2 fw-bold hiji-text-enseigne">{{ 'visualize.survey-chart.Enseigne' | translate }} (n={{ data.nbEnseigneStats }})</span>
        </div>
      }
      @if (data.withSurveyResultsAvailable && !isInFuture(data.surveyResultsAvailableDate) && data.withSocieteResults) {
        <div class="col-auto">
          <ic2-switch size="small" [(ngModel)]="showSociete" (ngModelChange)="buildData()" [disabled]="data.nbSocieteStats < data.nbMiniUsersStats"></ic2-switch>
          <span class="ms-2 fw-bold hiji-text-societe">{{ 'visualize.survey-chart.Société' | translate }} (n={{ data.nbSocieteStats }})</span>
        </div>
      }
      @if (data.withSurveyResultsAvailable && !isInFuture(data.surveyResultsAvailableDate) && data.withRegionResults) {
        <div class="col-auto">
          <ic2-switch size="small" [(ngModel)]="showRegion" (ngModelChange)="buildData()" [disabled]="data.nbRegionStats < data.nbMiniUsersStats"></ic2-switch>
          <span class="ms-2 fw-bold hiji-text-region">{{ 'visualize.survey-chart.Region' | translate }} (n={{ data.nbRegionStats }})</span>
        </div>
      }
      @if (data.withSurveyResultsAvailable && !isInFuture(data.surveyResultsAvailableDate) && data.withEtablissementResults) {
        <div class="col-auto">
          <ic2-switch
            size="small"
            [(ngModel)]="showEtablissement"
            (ngModelChange)="buildData()"
            [disabled]="data.nbEtablissementStats < data.nbMiniUsersStats"
          ></ic2-switch>
          <span class="ms-2 fw-bold hiji-text-etablissement">{{ 'visualize.survey-chart.Etablissement' | translate }} (n={{ data.nbEtablissementStats }})</span>
        </div>
      }
    </div>
  </div>
}
