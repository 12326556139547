import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L } from '@ic2/ic2-lib';
import { Bilan, VisualizeBilanService } from 'common';

@Component({
    selector: 'hiji-visualize-bilan',
    templateUrl: './visualize-bilan.component.html',
    styleUrls: ['./visualize-bilan.component.scss'],
    standalone: false
})
export class VisualizeBilanComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private visualizeBilanService = inject(VisualizeBilanService);

  bilan: Bilan = null;
  loading: boolean = true;

  ngOnInit(): void {
    this.visualizeBilanService.getBilan(this.route.snapshot.params['idBilan']).subscribe(
      (data) => {
        this.loading = false;
        this.bilan = data;
      },
      (err) => {
        L.e(err);
        this.router.navigate(['/']);
      }
    );
  }

  formatRating(rating: string) {
    return parseFloat(rating).toFixed(0);
  }
}
