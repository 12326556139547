import { Component, OnInit, input } from '@angular/core';
import { VizInquiryAnswerDTO } from 'common';

@Component({
    selector: 'hiji-visualize-sollicitation360-card',
    templateUrl: './visualize-sollicitation360-card.component.html',
    styleUrls: ['./visualize-sollicitation360-card.component.scss'],
    standalone: false
})
export class VisualizeSollicitation360CardComponent implements OnInit {
  readonly inquiryAnswer = input<VizInquiryAnswerDTO>(undefined);

  constructor() {}

  ngOnInit(): void {}
}
