import { Component, OnInit, inject, input } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { AuthService, ExchangeItemDTO } from 'common';

@Component({
    selector: 'hiji-visualize-exchange-card',
    templateUrl: './visualize-exchange-card.component.html',
    styleUrls: ['./visualize-exchange-card.component.scss'],
    standalone: false
})
export class VisualizeExchangeCardComponent implements OnInit {
  private authService = inject(AuthService);

  string_to_slug = string_to_slug;
  readonly exchange = input<ExchangeItemDTO>(undefined);
  idUserLogged: number;
  constructor() {
    const authService = this.authService;

    this.idUserLogged = authService.userBundle.user.idUser;
  }

  ngOnInit(): void {}
}
