<div class="container-fluid h-100 d-flex flex-column fit-height">
  @if (loading) {
    <div class="row">
      <div class="col">
        <i class="fa fa-spinner spinning"></i>
      </div>
    </div>
  }
  @if (!loading) {
    <div class="container-fluid overflow-auto flex-grow-1 mt-1">
      @for (group of groups; track group) {
        <div class="row mt-3">
          <hiji-group-card class="pointer" [group]="group" (click)="selectedGroup(group)"></hiji-group-card>
        </div>
      }
      <div class="row mt-3">
        <div class="col">
          <a class="btn btn-primary" routerLink="/group/create">{{ 'group.list.Créer une équipe' | translate }}</a>
        </div>
      </div>
    </div>
  }
</div>
