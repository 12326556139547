import { Component, Input, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnvService, string_to_slug } from '@ic2/ic2-lib';
import { AuthService, Config, DateUtils, HijiBundle, ModulesDTO, UserActionsDTO, UserActionsEMADTO } from 'common';

@Component({
  selector: 'hiji-user-actions-ema',
  templateUrl: './user-actions-ema.component.html',
  styleUrls: ['./user-actions-ema.component.scss'],
  standalone: false,
})
export class UserActionsEmaComponent {
  private authService = inject(AuthService);
  envService = inject<EnvService<Config>>(EnvService);

  @Input()
  data: UserActionsEMADTO;
  @Input()
  ua: UserActionsDTO;
  modulesDTO: ModulesDTO;
  me: boolean;
  rdvDate: Date;
  nbObsToDo: number = 1;
  loadingPlanRDV: boolean = false;
  string_to_slug = string_to_slug;
  DateUtils: typeof DateUtils = DateUtils;

  constructor() {
    const route = inject(ActivatedRoute);
    const authService = this.authService;

    this.modulesDTO = (<HijiBundle>this.authService.userBundle.data).modules;
    this.me = +route.snapshot.params['idUser'] === authService.userBundle.user.idUser;
    this.rdvDate = new Date();
    this.rdvDate.setTime(this.rdvDate.getTime() + 1000 * 60 * 60 * 24 * 30); //+30J
    this.rdvDate.setMinutes(0);
  }

  needsToObserve(): boolean {
    if (this.data.nextRDV === null) return this.data.nbObsDoneSinceLastEMA === 0; //cas ou on a pas de planification
    if (this.me) return this.data.nextRDV.nbObsToDo - this.data.nbAutoObsDoneSinceLastEMA > 0;
    else return this.data.nbObsDoneSinceLastEMA === 0;
  }
}
