<div class="row">
  <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
    <div class="bg-white border rounded-3 p-3">
      <div class="d-flex align-items-center mb-3">
        <i class="fa-light fa-fire fa-2x text-primary p-2 me-2 align-icon"></i>
        <div class="my-fs-md-4 my-fs-2 align-text-bottom">
          {{ 'user-actions.coaching-actions.Actions de coaching' | translate }}
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>{{ 'ema.share.encouraging-words.coaching-actions.table.Action' | translate }}</th>
              <th>{{ 'ema.share.encouraging-words.coaching-actions.table.Cible' | translate }}</th>
              <th>
                {{ 'ema.share.encouraging-words.coaching-actions.table.Période' | translate }}
                <i class="fa-light fa-calendar"></i>
              </th>
              <th>
                {{ 'ema.share.encouraging-words.coaching-actions.table.Réalisé' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            @for (coachingAction of coachingActions(); track $index) {
              <tr>
                <td>
                  {{ coachingAction.predefinedCoachingAction.action | ms }}
                </td>
                <td>
                  @if (coachingAction.coachingAction.idTag !== null) {
                    <i class="fa-light fa-tools me-2"></i>
                    {{ coachingAction.tagName | ms }}
                  } @else {
                    <i class="fa-light fa-bullseye-arrow me-2"></i>
                    {{ coachingAction.objectiveName | ms }}
                  }
                </td>
                <td [class.text-warning]="DateUtils.isPastDay(coachingAction.coachingAction.end)">
                  {{ coachingAction.coachingAction.start | localizedDate }} - {{ coachingAction.coachingAction.end | localizedDate }}
                </td>
                <td class="text-center">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [checked]="coachingAction.coachingAction.done"
                    (click)="toggle($event, coachingAction)"
                    [ngbTooltip]="
                      coachingAction.coachingAction.done
                        ? ('ema.share.coaching-actions.card.Par \{\{user\}\} le \{\{date\}\}'
                          | translate: { user: coachingAction.userDone, date: coachingAction.coachingAction.doneDate | localizedDate })
                        : null
                    "
                  />
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>

      <!--
      @for (coachingAction of coachingActions(); track $index) {
        <div class="row">
          <div class="col">{{ coachingAction | json }}</div>
        </div>
      }
      -->
    </div>
  </div>
</div>
