<div class="container-fluid h-100 d-flex flex-column fit-height pb-4">
  @if (loading) {
    <div class="row">
      <div class="col">
        <i class="fa fa-spinner spinning"></i>
      </div>
    </div>
  }

  @if (!loading) {
    <div class="row">
      <div class="bg-light-30p">
        <div class="row">
          <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 my-fs-4 mt-4">
            <b>{{ "group.search-user.Ajouter une personne à l'équipe :" | translate }}</b>
          </div>
        </div>
        <div class="row mt-4 p-2">
          <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 my-fs-2 my-fs-xxl-3 mt-3">
            <input
              class="form-control"
              name="searchUsers"
              (keyup)="searchUser($event)"
              type="text"
              placeholder="{{ 'group.search-user.Rechercher' | translate }}"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row overflow-auto">
        <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 my-fs-2 my-fs-xxl-3 mt-3">
          @for (user of users; track user) {
            <div
              class="rounded-3 bg-white p-2 mt-2"
              [class.bg-light]="isSelected(user)"
              [class.bg-white]="!isSelected(user) && !isSelectedNewUser(user)"
              [class.bg-secondary]="isSelectedNewUser(user)"
              (click)="toggleUser(user)"
              >
              <div class="my-2" [class.pointer]="!isSelected(user)">
                <div class="row g-2 align-items-center">
                  <div class="col-auto">
                    <hiji-user-avatar
                      style="width: 50px; height: 50px"
                      [idUser]="user.idUser"
                      [avatarExtension]="user.avatarExtension"
                      [defaultBlueAvatar]="true"
                    ></hiji-user-avatar>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col-auto">{{ user.firstName }} {{ user.lastName }}</div>
                      <div>{{ user.functionName }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <div class="row my-4 justify-content-center align-items-center">
        <div class="col-auto">
          <button class="btn btn-secondary" type="submit" (click)="returnToGroup()">
            <i class="fa-light fa-long-arrow-alt-left me-2"></i>
            {{ 'group.search-user.Retour' | translate }}
          </button>
        </div>
        @if (selectedUsers.length > 0) {
          <div class="col-auto">
            <button class="btn btn-secondary" type="submit" (click)="addToGroup()">
              {{ 'group.search-user.Ajouter ces ' | translate }} {{ selectedUsers.length }} {{ 'group.search-user.utilisateurs ' | translate }}
            </button>
          </div>
        }
      </div>
    }
  </div>
