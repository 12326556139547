<div class="container-fluid h-100 p-4">
  <div class="row">
    <div class="col">
      <h2>{{ 'contact.Contact' | translate }}</h2>
    </div>
  </div>

  @if (manualHierarchicalManagement) {
    <div class="row mt-3">
      <div class="col">
        <div class="bg-white rounded p-4">
          <div class="row align-items-center">
            <div class="col-auto">
              <i class="fa-light fa-warning fa-3x"></i>
            </div>
            <div class="col">
              <b>{{ 'contact.Besoin de modifier votre équipe ?' | translate }}</b>
              <br />
              <p>
                {{
                'contact.Vous avez la possibilité de supprimer un collaborateur en cliquant sur son nom depuis la page d\'accueil puis "Supprimer de mon équipe"'
                | translate
                }}
              </p>
              <p>
                {{
                'contact.Vous avez aussi la possibilité d\'ajouter un collaborateur via la page d\'accueil en cliquant sur "Ajouter un collaborateur à mon équipe", en mettant son nom/prénom, cliquez sur votre collaborateur et cliquez sur"Ajouter à mon équipe"'
                | translate
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  <div class="row mt-3">
    <div class="col">
      <textarea class="form-control" rows="5" [(ngModel)]="message" placeholder="{{ 'contact.Dites-nous tout !' | translate }}"></textarea>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      {{ 'rgpd.hiji vient de collecter des informations vous concernant, pour en savoir plus' | translate }}
      <a class="btn-link link-dark" [routerLink]="null" (click)="showPopup()">{{ 'rgpd.cliquez ici' | translate }}</a>
    </div>
  </div>
  <div class="row mt-3 justify-content-end">
    <div class="col-auto">
      <button class="btn btn-secondary" type="button" [disabled]="loading" (click)="send()">
        {{ 'contact.Envoyer' | translate }}
        @if (loading) {
          <i class="fa-light fa-spinner spinning ms-2"></i>
        }
      </button>
    </div>
  </div>
</div>
