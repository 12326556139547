<div class="container-fluid pt-2 pb-4">
  @if (loading) {
    <div class="row">
      <div class="col-12 text-center">
        <i class="fa-light fa-spinner spinning"></i>
      </div>
    </div>
  }
  @if (!loading) {
    <!-- caché sur demande de benoit
    <div class="row align-center">
      <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
        <div class="bg-white rounded-3 border p-2">
          <div class="row">
            <div class="col text-center" *ngIf="data.nbAnswers === 1">
              {{ "visualize-survey.\{\{nbAnswers\}\} répondant à l'enquête" | translate: { nbAnswers: data.nbAnswers } }}
            </div>
            <div class="col text-center" *ngIf="data.nbAnswers > 1">
              {{ "visualize-survey.\{\{nbAnswers\}\} répondants à l'enquête" | translate: { nbAnswers: data.nbAnswers } }}
            </div>
          </div>
          <div class="row mt-1" *ngIf="data.nbAnswers < data.nbMiniUsersStats && !isInFuture(data.surveyResultsAvailableDate)">
            <div class="col text-center">
              {{
              'visualize-survey.\{\{nbMiniUsersStats\}\} réponses minimales pour comparer les réponses'
              | translate: { nbMiniUsersStats: data.nbMiniUsersStats }
              }}
            </div>
          </div>
          <div class="row mt-1" *ngIf="isInFuture(data.surveyResultsAvailableDate)">
            <div class="col text-center">
              {{
              "visualize-survey.Les réponses de l'ensemble des collaborateurs seront disponibles le \{\{date\}\}"
              | translate: { date: data.surveyResultsAvailableDate | localizedDate }
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    -->
    <div class="row align-center mt-3">
      <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
        <hiji-visualize-survey-chart [data]="data"></hiji-visualize-survey-chart>
      </div>
    </div>
    <!--<hiji-step3-share-chart [exchange]="exchange" [selectedRepositories]="selectedRepositories"></hiji-step3-share-chart>-->
    <!--
    <hiji-step3-skill *ngFor="let skill of skills" [skill]="skill" [exchange]="exchange"></hiji-step3-skill>
    -->
    @for (observableGesture of data.observableGestures | orderBy: 'order'; track observableGesture) {
      <div class="row align-center mt-4">
        <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3">
          <div class="bg-white rounded-3 border p-2">
            <hiji-observable-gesture-result
              [criteria]="getCriteria(observableGesture.idCriteria)"
              [observableGesture]="observableGesture"
              [answers]="computeAnswers(observableGesture)"
              [textAnswers]="computeTextAnswers(observableGesture)"
              [calculateDonutByNumberOfAnswerTypes]="true"
              [showSkill]="!data.hideSkill"
            ></hiji-observable-gesture-result>
            <!--<hiji-step3-observable-gesture-results
            [criteria]="getCriteria(observableGesture.idCriteria)"
            [observableGesture]="observableGesture"
          ></hiji-step3-observable-gesture-results>-->
        </div>
      </div>
    </div>
  }
}
</div>
