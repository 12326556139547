<div class="container-fluid h-100 d-flex flex-column">
  @if (loading) {
    <div class="row">
      <div class="col-12 text-center">
        <i class="fa-light fa-spinner spinning"></i>
      </div>
    </div>
  }
  @if (!loading) {
    <div class="row mt-2">
      <div class="" routerLink="/visualize">
        <button class="btn btn-secondary border">
          <i class="fa-light fa-long-arrow-alt-left"></i>
          &nbsp; {{ 'visualize.bilan.Retour' | translate }}
        </button>
      </div>
      <div class="col col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 animate__animated animate__bounceInUp">
        <div class="container-fluid bg-white border rounded-3 my-fs-4">
          <div class="row">
            <div class="col-auto mt-2">
              {{ 'visualize.share-card.Restitution du' | translate }} :
              <div class="fw-bold">{{ bilan.comiteDate | localizedDate }}</div>
            </div>
          </div>
          <div class="row align-items-end justify-content-evenly mt-2">
            <div class="col-4">
              Rating
              <div class="bg-primary p-2 rounded-3 text-white fw-bold">{{ formatRating(bilan.rating) }}</div>
            </div>
            <div class="col-4">
              Potentiel
              <div class="bg-primary p-2 rounded-3 text-white fw-bold">{{ bilan.potentiel }}</div>
            </div>
            <div class="col">
              Prochain grade saison {{ bilan.comiteDate.getFullYear() }} / {{ bilan.comiteDate.getFullYear() + 1 }}
              <div class="bg-primary p-2 rounded-3 text-white fw-bold">{{ bilan.grade }}</div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              Talents
              <div class="bg-light-30p rounded-3 p-2" style="white-space: pre-wrap">{{ bilan.talents }}</div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              Axes de développement
              <div class="bg-light-30p rounded-3 p-2" style="white-space: pre-wrap">{{ bilan.improvement }}</div>
            </div>
          </div>
          <div class="row mt-3 pb-2">
            <div class="col-12">
              Synthese
              <div class="bg-light-30p rounded-3 p-2" style="white-space: pre-wrap">{{ bilan.synthese }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</div>
