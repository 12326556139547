import { Component, inject, input } from '@angular/core';
import { EnvService } from '@ic2/ic2-lib';
import { Config, ResetPwdService, UserActionsDTO } from 'common';

@Component({
    selector: 'hiji-user-actions-reset-pwd-pincode',
    templateUrl: './user-actions-reset-pwd-pincode.component.html',
    styleUrl: './user-actions-reset-pwd-pincode.component.scss',
    standalone: false
})
export class UserActionsResetPwdPincodeComponent {
  env = inject<EnvService<Config>>(EnvService);
  private resetPwdService = inject(ResetPwdService);

  readonly data = input<UserActionsDTO>(undefined);
  readonly me = input<boolean>(false);
  loading: boolean = false;
  codePin: string = null;

  generateResetPwdToken() {
    this.loading = true;
    this.resetPwdService
      .generatePinCode(this.data().member.idUser)
      .defaultOnErrorAnd(() => {
        this.loading = false;
      })
      .execute((data) => {
        this.loading = false;
        this.codePin = data;
      });
  }
}
