<hiji-wavy-container>
  <div class="container bg-white rounded py-3 m-3 mx-sm-auto" style="z-index: 10">
    <div class="row">
      <div class="col">
        <h3>{{ "kiosk-login.Saississez votre code de connexion pour répondre à l'enquête" | translate }}</h3>
      </div>
    </div>
    @if (error) {
      <div class="row mt-3">
        <div class="col">
          <ul class="errors">
            <li>{{ 'kiosk-login.Le code de connexion semble être invalide' | translate }}</li>
          </ul>
        </div>
      </div>
    }
    <div class="row mt-3 justify-content-center">
      @if (!loading) {
        <div class="col h-200px">
          <input
            class="form-control py-3 text-center big-text"
            style="height: 100% !important"
            type="text"
            [(ngModel)]="code"
            (ngModelChange)="check()"
            (input)="code = code.toUpperCase()"
            />
          </div>
        }
        @if (loading) {
          <div class="col-auto">
            <i class="fa-light fa-spinner spinning big-text"></i>
          </div>
        }
      </div>
    </div>
  </hiji-wavy-container>
