import { Component, OnInit, input } from '@angular/core';
import { VizBilanDTO } from 'common';

@Component({
    selector: 'hiji-visualize-bilan-card',
    templateUrl: './visualize-bilan-card.component.html',
    styleUrls: ['./visualize-bilan-card.component.scss'],
    standalone: false
})
export class VisualizeBilanCardComponent implements OnInit {
  readonly bilan = input<VizBilanDTO>(undefined);
  constructor() {}

  ngOnInit(): void {}
}
