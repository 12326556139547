import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L, string_to_slug } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import { GroupDTO, GroupService, GroupType, Ic2ToastrService, UserFuncAvatarDTO, UserSearchService } from 'common';

@Component({
    selector: 'hiji-group-search-user',
    templateUrl: './group-search-user.component.html',
    styleUrls: ['./group-search-user.component.scss'],
    standalone: false
})
export class GroupSearchUserComponent implements OnInit {
  private groupService = inject(GroupService);
  private router = inject(Router);
  private UserSearchService = inject(UserSearchService);
  private route = inject(ActivatedRoute);
  private ic2ToastrService = inject(Ic2ToastrService);
  private translate = inject(TranslateService);

  GroupType: typeof GroupType = GroupType;
  loading: boolean = true;
  users: UserFuncAvatarDTO[] = [];
  groupData: GroupDTO = null;
  selectedUsers: UserFuncAvatarDTO[] = [];
  string_to_slug = string_to_slug;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.groupService.getGroup(GroupType.GROUP, params['idGroup']).subscribe(
        (data) => {
          this.groupData = data;
          this.loading = false;
        },
        (err) => {
          this.loading = false;

          L.e(err);
        }
      );
    });
  }
  isSelected(selectedUser: UserFuncAvatarDTO) {
    return this.groupData.users.find((user) => user.idUser === selectedUser.idUser) !== undefined;
  }
  isSelectedNewUser(selectedUser: UserFuncAvatarDTO) {
    return this.selectedUsers.find((user) => user.idUser === selectedUser.idUser) !== undefined;
  }

  toggleUser(selectedUser: UserFuncAvatarDTO) {
    if (this.isSelected(selectedUser)) return;
    if (this.selectedUsers.find((user) => user.idUser === selectedUser.idUser) === undefined) {
      this.selectedUsers.push(selectedUser);
    } else {
      this.selectedUsers.splice(this.selectedUsers.indexOf(selectedUser), 1);
    }
  }

  searchUser(event) {
    if (event.target.value.length > 0) {
      this.UserSearchService.searchUser(event.target.value, false).subscribe(
        (data) => {
          this.loading = false;
          this.users = data;
        },
        (err) => {
          this.loading = false;
          if (err.isBusinessError() && err.data && err.data['code'] === 'no_enseigne')
            this.ic2ToastrService.showSuccess(
              this.translate.instant(
                "group.search-user.Vous n'appartenez pas à une enseigne et ne pouvez pas faire de recherche d'utilisateurs, contactez l'assistance"
              )
            );
          L.e(err);
        }
      );
    } else {
      this.users = [];
    }
  }
  addToGroup() {
    this.groupData.users = this.groupData.users.concat(this.selectedUsers);
    this.groupService
      .saveGroup(
        this.groupData.idGroup,
        this.groupData.groupName,
        this.groupData.users.map((user) => user.idUser)
      )
      .subscribe(
        (data) => {
          this.router.navigate(['/group/edit/', this.groupData.idGroup, this.groupData.groupType.id, string_to_slug(this.groupData.groupName)]);
        },
        (err) => {
          L.e(err);
        }
      );
  }
  returnToGroup() {
    this.router.navigate(['/group/edit/', this.groupData.idGroup, this.groupData.groupType.id, string_to_slug(this.groupData.groupName)]);
  }
}
