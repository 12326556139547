<div class="container-fluid full-height d-flex flex-column">
  @if (loading) {
    <div class="row">
      <div class="col-12 text-center">
        <i class="fa-light fa-spinner spinning"></i>
      </div>
    </div>
  }
  @if (!loading) {
    <div class="row">
      <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__lightSpeedInLeft">
        <hiji-group-card [group]="group"></hiji-group-card>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
        <a class="text-body pointer" (click)="goToFeedback()">
          @if (modulesDTO.moduleFBS) {
            <div class="bg-white border rounded-3 p-2">
              <i class="fa-light fa-comments fa-2x text-primary p-2" aria-hidden="true"></i>
              <span class="my-fs-md-4 my-fs-2 align-text-bottom">
                {{ 'group.actions.Feedback groupe' | translate }}
              </span>
            </div>
          }
        </a>
      </div>
      <!--
      <div class="row mt-2 py-4 justify-content-center">
        <div class="col-auto">
          <a type="button" class="btn btn-secondary"  routerLink="">
            <i class="fa-light fa-comments"></i>
            {{ 'group.actions.Demander du feedback (créer un questionnaire 360 personnalisé ou spontané)' | translate }}
          </a>
        </div>
      </div>
      -->
      @if (group.groupType === GroupType.GROUP) {
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
          <a class="text-body pointer" [routerLink]="['/group/edit', group.idGroup, group.groupType.id, string_to_slug(group.groupName)]">
            <div class="bg-white border rounded-3 p-2">
              <i class="fa-light fa-pencil-alt fa-2x text-primary p-2" aria-hidden="true"></i>
              <span class="my-fs-md-4 my-fs-2 align-text-bottom">
                {{ "group.actions.Modifier l'équipe" | translate }}
              </span>
            </div>
          </a>
        </div>
      }
      @if (group.groupType !== GroupType.GROUP) {
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
          <a class="text-body pointer" [routerLink]="['/group/edit', group.idGroup, group.groupType.id, string_to_slug(group.groupType.lib)]">
            <div class="bg-white border rounded-3 p-2">
              <i class="fa-light fa-eye fa-2x text-primary p-2" aria-hidden="true"></i>
              <span class="my-fs-md-4 my-fs-2 align-text-bottom">
                {{ "group.actions.Details de l'équipe" | translate }}
              </span>
            </div>
          </a>
        </div>
      }
      @if (group.groupType === GroupType.GROUP) {
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
          <a class="text-body pointer" (click)="deleteAGroup(group.idGroup)">
            <div class="bg-white border rounded-3 p-2">
              <i class="fa-light fa-trash fa-2x text-primary p-2" aria-hidden="true"></i>
              <span class="my-fs-md-4 my-fs-2 align-text-bottom">
                {{ "group.actions.Supprimer l'équipe" | translate }}
              </span>
            </div>
          </a>
        </div>
      }
    </div>
  }
</div>
