import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { string_to_slug } from '@ic2/ic2-lib';
import { GroupDTO, GroupService, GroupType } from 'common';

@Component({
    selector: 'hiji-group-edit',
    templateUrl: './group-edit.component.html',
    styleUrls: ['./group-edit.component.scss'],
    standalone: false
})
export class GroupEditComponent implements OnInit {
  private groupService = inject(GroupService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  string_to_slug = string_to_slug;
  GroupType: typeof GroupType = GroupType;
  loading: boolean = true;
  data: GroupDTO = null;
  ngOnInit(): void {
    if (this.router.url === '/group/create' && this.data === null) {
      this.data = new GroupDTO();
      this.data.groupType = GroupType.GROUP;
      this.loading = false;
    } else {
      this.route.params.subscribe((params) => {
        this.groupService
          .getGroup(GroupType.mapFromId(parseInt(params['typeGroup'], 10)), params['idGroup'])
          .defaultOnErrorAnd((err) => {
            this.router.navigate(['/']);
          })
          .execute((data) => {
            this.data = data;
            this.loading = false;
          });
      });
    }
  }
  saveGroup() {
    this.groupService
      .saveGroup(
        this.data.idGroup,
        this.data.groupName,
        this.data.users.map((user) => user.idUser)
      )
      .defaultOnError()
      .execute((data) => {
        this.data.idGroup = data;
        this.router.navigate(['/group', this.data.idGroup, GroupType.GROUP.id, string_to_slug(this.data.groupName)]);
      });
  }

  goToGroup() {
    this.groupService
      .saveGroup(
        this.data.idGroup,
        this.data.groupName,
        this.data.users.map((user) => user.idUser)
      )
      .defaultOnError()
      .execute((data) => {
        this.data.idGroup = data;
        this.router.navigate(['group/edit/' + this.data.idGroup + '/' + GroupType.GROUP.id + '/' + string_to_slug(this.data.groupName) + '/add']);
      });
  }

  deleteUsers(idUser) {
    this.data.users = this.data.users.filter((user) => user.idUser !== idUser);
  }
}
