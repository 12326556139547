<div class="container-fluid bg-white h-100 pb-3" style="overflow-y: hidden">
  @if (loading || loadingHomeData) {
    <div class="row mt-1 mb-3">
      <div class="col text-center">
        <i class="fa fa-spinner spinning fa-2x"></i>
      </div>
    </div>
  } @else {
    @if (!loadingHomeData && userMember !== null) {
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
          <hiji-user-card [user]="userMember" [showCallToAction]="true"></hiji-user-card>
        </div>
      </div>
    }

    <!-- afficher les invitation des managers -->
    @if (homeDTO && me) {
      <div class="row">
        @for (managerInvitation of homeDTO.managerInvitations; track $index) {
          <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
            <hiji-manager-invitation-card
              [data]="managerInvitation"
              (remove)="homeDTO.managerInvitations.splice(homeDTO.managerInvitations.indexOf(managerInvitation), 1)"
            ></hiji-manager-invitation-card>
          </div>
        }
      </div>
    }

    @if (me && !hideVisualize) {
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
          <a class="text-body pointer" routerLink="/visualize">
            <div class="bg-white border rounded-3 p-2 d-flex align-items-center">
              <i class="fa-light fa-display-chart-up fa-3x text-tertiary p-4 align-icon" aria-hidden="true"></i>
              <span class="my-fs-md-4 align-text-bottom text-uppercase">
                <b>{{ 'specific.skillsup.mycoaching.Visualiser mon historique de coaching' | translate }}</b>
              </span>
            </div>
          </a>
        </div>
      </div>
    }

    <div class="row mt-4">
      <hr />
      <div class="col-12 fw-bold text-center my-fs-md-4 text-uppercase">
        {{ 'specific.skillsup.mycoaching.Mes coachings à venir' | translate }}
      </div>
    </div>

    @if (
      modulesDTO.moduleEMA.enabled &&
      userActionsDTO.userActionsEMADTO &&
      (me ||
      userActionsDTO.isInMyTeam ||
      (modulesDTO.moduleEMA.allowN2PlusToAuditExchanges && userActionsDTO.isInMyGlobalTeam) ||
      userActionsDTO.userActionsEMADTO.isCoach) &&
      userActionsDTO.userActionsEMADTO.isEMATarget
      ) {
      <hiji-user-actions-ema [data]="userActionsDTO.userActionsEMADTO" [ua]="userActionsDTO"></hiji-user-actions-ema>
    }
    @if (
      modulesDTO.moduleEMA.enabled &&
      (me || userActionsDTO.isInMyTeam || userActionsDTO.isInMyGlobalTeam || (userActionsDTO.userActionsEMADTO && userActionsDTO.userActionsEMADTO.isCoach)) &&
      modulesDTO.moduleEMA.teamExchangeRecapEnabled &&
      userActionsDTO.userActionsEMADTO.team.length > 0
      ) {
      <hiji-user-actions-team [data]="userActionsDTO"></hiji-user-actions-team>
    }

    <hiji-user-actions-campaign180 [data]="userActionsDTO" [me]="me"></hiji-user-actions-campaign180>

    @if (me) {
      <!-- certification rejoindre une session -->
      <div class="row">
        @if (modulesDTO.moduleCertification) {
          <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
            <a class="text-body" [routerLink]="['/join']">
              <div class="bg-white border rounded-3">
                <div class="row g-1">
                  <div class="col-auto d-flex align-items-center">
                    <i class="fa-light fa-right-to-bracket fa-3x text-tertiary p-4 align-icon"></i>
                  </div>
                  <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
                    <div class="row">
                      <div class="col-auto text-uppercase">
                        <b>{{ 'specific.skillsup.mycoaching.Rejoindre une session de coaching' | translate }}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        }
      </div>
    }
  }

  @if (loading || loadingHomeData) {
    <hr />
    <div class="row mt-1 mb-3">
      <div class="col text-center">
        <i class="fa fa-spinner spinning fa-2x"></i>
      </div>
    </div>
  } @else if (homeDTO && me) {
    <hr />
    <div class="row">
      <div class="col-12 fw-bold text-center my-fs-md-4 text-uppercase">
        {{ 'specific.skillsup.mycoaching.Mes coachés' | translate }}
      </div>
    </div>

    <!-- son équipe -->
    @for (member of homeDTO.team | orderBy: 'lastExchangeDate'; track $index) {
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
          <a class="text-body" routerLink="/skillsup/coaching/{{ member.idUser }}/{{ string_to_slug(member.firstName + '-' + member.lastName) }}">
            <hiji-user-card [user]="member" [showCallToAction]="true"></hiji-user-card>
          </a>
        </div>
      </div>
    }

    @if (!modulesDTO.frontConfiguration.hideHomeTeamButtons) {
      <div class="row">
        <div class="col-12 col-sm-8 col-xxl-6 offset-sm-2 offset-xxl-3 mt-3 animate__animated animate__fadeInLeft">
          <a class="text-body" routerLink="/search">
            <div class="bg-white border rounded-3">
              <div class="row g-1">
                <div class="col-auto d-flex align-items-center">
                  <i
                    class="fa-light fa-3x text-tertiary p-4 align-icon"
                    [class.fa-search]="!manualHierarchicalManagement"
                    [class.fa-plus]="manualHierarchicalManagement"
                  ></i>
                </div>
                <div class="col my-fs-2 my-fs-lg-3 d-flex flex-column justify-content-center">
                  <div class="row">
                    <div class="col-auto text-uppercase">
                      {{
                      manualHierarchicalManagement
                      ? ('specific.skillsup.mycoaching.Ajouter un coaché' | translate)
                      : ('specific.skillsup.mycoaching.Rechercher un coaché' | translate)
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    }
  }
</div>
