import { DecimalPipe } from '@angular/common';
import { Component, OnInit, inject, input } from '@angular/core';
import { MsPipe } from '@ic2/ic2-lib';
import { ChartConfiguration } from 'chart.js';
import { EMAObjectiveFormula, KpiDTO, LocalizedDatePipe } from 'common';

@Component({
  selector: 'hiji-kpi-stats',
  templateUrl: './kpi-stats.component.html',
  styleUrls: ['./kpi-stats.component.scss'],
  standalone: false,
})
export class KpiStatsComponent implements OnInit {
  private localizedDatePipe = inject(LocalizedDatePipe);
  private msPipe = inject(MsPipe);
  private decimalPipe = inject(DecimalPipe);

  error: boolean = false;
  readonly data = input<KpiDTO[]>(undefined);

  lineChartData: ChartConfiguration['data'] = {
    labels: [],
    datasets: [],
  };

  lineChartOptions: ChartConfiguration['options'] = {
    hover: {
      mode: 'nearest',
      intersect: true,
      axis: 'y',
    },
    animation: {
      duration: 10,
    },

    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: 100, //Pour le moment comme c'est utilisé que chez armatis et qu'on a tout les objectifs en pourcentage (showAsPercentageInGraph) on se prends pas trop la tête
        //demain il faudra peut être check si il y a que des objectifs avec showAsPercentageInGraph et si oui mettre suggestedMax à 100 sinon à null
        grid: {
          display: true,
        },
      },
    },
    parsing: {
      xAxisKey: 'creationDate',
      yAxisKey: 'value',
    },
    plugins: {
      tooltip: {
        enabled: true,
        usePointStyle: true,
        callbacks: {
          // To change title in tooltip
          title: (item): string | string[] => {
            return item[0].label + '';
          },
          // To change label in tooltip
          label: (tooltipItem): string | string[] => {
            //console.log(tooltipItem);
            if (tooltipItem.formattedValue === null || tooltipItem.formattedValue === undefined) return tooltipItem.dataset.label + ' ' + 'N/A';
            let val = (<any>tooltipItem.raw).value;
            try {
              return (
                tooltipItem.dataset.label +
                ' ' +
                this.decimalPipe.transform(val, '1.0') +
                '% (' +
                this.decimalPipe.transform((<any>tooltipItem.raw).result, '1.0-2') +
                '/' +
                ((<any>tooltipItem.raw).objective === null ? 'N/A' : this.decimalPipe.transform((<any>tooltipItem.raw).objective, '1.0-2')) +
                ')'
              );
            } catch (e) {
              console.error('value is ', tooltipItem.formattedValue, typeof tooltipItem.formattedValue, val);
              throw e;
            }
          },
        },
      },
    },
  };

  ngOnInit(): void {
    const predefinedColors = ['#f1b314', '#30c9eb', '#ff5733', '#8c6ad9', '#e02e38']; // Liste de couleurs prédéfinies

    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    const dates = this.data()
      .filter((ex) => ex.results.filter((r) => r.monthly).length > 0)
      .flatMap((ex) => ex.results.filter((r) => r.monthly).map((r) => r.creationDate))
      .map((d) => d.toDateString())
      .filter(unique)
      .map((d) => new Date(d))
      .sort((a, b) => a.getTime() - b.getTime());

    if (dates.length < 2) {
      this.error = true;
      return;
    }

    this.lineChartData.datasets = this.data()
      .filter((ex) => ex.results.filter((r) => r.monthly).length > 0)
      .map((ex, index) => {
        const colorIndex = index % predefinedColors.length;
        return {
          data: dates.map((date) => {
            let point = ex.results
              .filter((r) => r.monthly)
              .filter((r) => r.creationDate.toDateString() === date.toDateString())
              .map((r) => {
                let value = r.result;
                if (ex.objective.showAsPercentageInGraph && r.objective !== 0 && r.objective !== null) {
                  if (ex.objective.formula === EMAObjectiveFormula.RO) value = (r.result / r.objective) * 100;
                  else if (ex.objective.formula === EMAObjectiveFormula.OR) value = (r.objective / r.result) * 100;
                  else throw new Error('Invalid formula');
                } else {
                  value = r.result;
                }
                return { ...r, value: value };
              });
            if (point.length === 0) return { creationDate: date, value: null } as any;
            else return point[0] as any;
          }),
          label: this.msPipe.transform(ex.objective.name),
          fill: false,
          spanGaps: true,
          borderColor: predefinedColors[colorIndex],
          pointBorderColor: predefinedColors[colorIndex],
          pointBackgroundColor: predefinedColors[colorIndex],
          backgroundColor: null,
        };
      });

    if (dates.length > 6) this.lineChartData.labels = dates.slice(-6).map((d) => this.localizedDatePipe.transform(d));
    else this.lineChartData.labels = dates.map((d) => this.localizedDatePipe.transform(d));
  }
}
