import { Component, input, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoachingActionService, DateUtils, EMACoachingActionDTO, ModalService } from 'common';

@Component({
  selector: 'hiji-user-actions-coaching-actions',
  standalone: false,
  templateUrl: './user-actions-coaching-actions.component.html',
  styleUrl: './user-actions-coaching-actions.component.scss',
})
export class UserActionsCoachingActionsComponent implements OnInit {
  private coachingActionService = inject(CoachingActionService);
  private modalService = inject(ModalService);
  private translate = inject(TranslateService);

  readonly coachingActions = input.required<EMACoachingActionDTO[]>();

  DateUtils = DateUtils;

  ngOnInit() {}

  toggle(event, coachingAction: EMACoachingActionDTO) {
    event.preventDefault();
    this.modalService.openModal(
      'confirm',
      coachingAction.coachingAction.done
        ? this.translate.instant('user-actions.coaching-actions.modal.Annuler la réalisation de cette action')
        : this.translate.instant('user-actions.coaching-actions.modal.Confirmer la réalisation de cette action'),
      () => {
        this.coachingActionService
          .toggleDone(coachingAction.coachingAction.idEMACoachingAction)
          .onErrorUseDefault()
          .execute((data) => {
            const index = this.coachingActions().findIndex((ca) => ca.coachingAction.idEMACoachingAction === coachingAction.coachingAction.idEMACoachingAction);
            this.coachingActions()[index].coachingAction = data;
          });
      }
    );
  }
}
