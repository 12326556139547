<div class="bg-white bg-light-30p-hover border rounded-3 p-2">
  <div class="row g-1">
    <div class="col-auto d-flex align-items-center">
      <hiji-user-avatar
        style="width: 80px; height: 80px"
        [idUser]="inquiryAnswer().idUser"
        [avatarExtension]="inquiryAnswer().avatarExtension"
        [defaultBlueAvatar]="true"
      ></hiji-user-avatar>
    </div>
    <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
      <div class="row">
        <div class="col-auto fw-bold">
          {{ inquiryAnswer().name | ms }}
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <span class="fw-bold">{{ inquiryAnswer().firstName }} {{ inquiryAnswer().lastName }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          {{ 'visualize.sollicitation360-card.Répondu le' | translate }}
          <b>{{ inquiryAnswer().answerDate | localizedDate }}</b>
        </div>
      </div>
      @if (inquiryAnswer().draft) {
        <div class="row">
          <div class="col-auto text-success">
            <i class="fa-light fa-clipboard me-1"></i>
            {{ 'visualize.sollicitation360-card.En brouillon' | translate }}
          </div>
        </div>
      }
      @if (inquiryAnswer().assessor) {
        <div class="row">
          <div class="col-auto text-warning">
            <i class="fa-light fa-marker me-1"></i>
            {{ 'visualize.sollicitation360-card.Vous êtes évaluateur' | translate }}
          </div>
        </div>
      }
    </div>
  </div>
</div>
