import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'common';

@Component({
    selector: 'hiji-kiosk-login',
    templateUrl: './kiosk-login.component.html',
    styleUrls: ['./kiosk-login.component.scss'],
    standalone: false
})
export class KioskLoginComponent implements OnInit {
  private authService = inject(AuthService);
  private router = inject(Router);
  private translate = inject(TranslateService);

  code: string = '';
  loading: boolean = false;
  error: boolean = false;
  constructor() {
    this.authService.isLoggedIn().subscribe((data) => {
      if (data) this.router.navigate(['/']);
      else this.authService.logout();
    });
  }

  ngOnInit(): void {}

  check() {
    if (this.code.length !== 6) return;
    this.authService.code = this.code;
    this.loading = true;
    this.authService.isLoggedIn().subscribe({
      next: (data) => {
        this.loading = false;
        if (data) this.router.navigate(['/']);
        else {
          this.code = '';
          this.error = true;
        }
      },
      error: (err) => {
        this.code = '';
        this.loading = false;
        this.error = true;
      },
    });
  }
}
