@if (this.opinionDataService.opinionFODTO !== null) {
  <div class="container-fluid h-100 pb-3">
    <div class="row justify-content-around">
      <div class="col-12">
        <div class="row animate__animated animate__bounce mt-5">
          <h1 class="h1 text-center">{{ 'opinion-question.list.A votre tour de nous observer' | translate }}</h1>
        </div>
      </div>
      @for (questionDTO of opinionDataService.opinionFODTO.listOpinionQuestion; track questionDTO) {
        <div class="row justify-content-center">
          <div class="col-sm-6 col-12 justify-content-center animate__animated animate__bounce mt-5 bg-white border rounded-3">
            <div class="row">
              <div class="col-12">
                <p class="m-1 p-2 text-center mt-2 my-fs-2 my-fs-md-4 fw-bold">{{ questionDTO.question | ms }}</p>
              </div>
            </div>
            <!-- <div class="row pt-1 justify-content-around mt-5 animate__animated animate__bounce">-->
            @if (!questionDTO.nps) {
              <div class="col-12 p-2 text-center my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
                <div class="row">
                  <div class="col-6">
                    <button
                      class="btn my-fs-2 my-fs-md-3"
                      [class.btn-outline-secondary]="!isAnswered(0, questionDTO.idOpinionQuestion)"
                      [class.btn-secondary]="isAnswered(0, questionDTO.idOpinionQuestion)"
                      (click)="like(0, questionDTO.idOpinionQuestion)"
                      >
                      {{ 'opinion-question.list.Non' | translate }}
                    </button>
                  </div>
                  <div class="col-6">
                    <button
                      class="btn my-fs-2 my-fs-md-3"
                      [class.btn-outline-secondary]="!isAnswered(1, questionDTO.idOpinionQuestion)"
                      [class.btn-secondary]="isAnswered(1, questionDTO.idOpinionQuestion)"
                      (click)="like(1, questionDTO.idOpinionQuestion)"
                      >
                      {{ 'opinion-question.list.Oui' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            }
            @if (questionDTO.nps) {
              <div class="col-12 p-2 text-center my-fs-1 my-fs-sm-2 my-fs-md-3 d-flex flex-column justify-content-center">
                <div class="row">
                  @for (i of npsValues; track i) {
                    <div class="col mt-1 p-0 mx-0">
                      <div class="justify-content-center">
                        @if (hasAnswerNps) {
                          <div>
                            <div (click)="npsApreciate(i, questionDTO.idOpinionQuestion)">
                              <i
                                class="fa-star fa-xl text-primary"
                                [class.fa-light]="getNPSAnswer(questionDTO.idOpinionQuestion) < i"
                                [class.fa-solid]="getNPSAnswer(questionDTO.idOpinionQuestion) >= i"
                              ></i>
                            </div>
                          </div>
                        }
                        @if (!hasAnswerNps) {
                          <div>
                            <div (click)="npsApreciate(i, questionDTO.idOpinionQuestion)">
                              <i class="fa-star fa-light fa-xl text-primary"></i>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  }
                  <div class="row">
                    <div class="col text-start mt-2 my-fs-1 my-fs-sm-2 my-fs-md-3">
                      {{ 'opinion-question.list.Tout à fait improbable' | translate }}
                    </div>
                    <div class="col text-end mt-2 my-fs-1 my-fs-sm-2 my-fs-md-3">
                      {{ 'opinion-question.list.Tout à fait probable' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }
      <div class="row justify-content-around animate__animated animate__bounce">
        <div class="col-12 col-sm-6 justify-content-center animate__animated animate__bounce mt-5 bg-white border rounded-3 text-center">
          <label class="m-1 p-2 text-center my-fs-4 fw-bold" for="suggestion">{{ 'opinion-question.list.Une suggestion?' | translate }}</label>
          <textarea
            class="form-control mb-2"
            style="height: 100px"
            name="suggestion"
            id="suggestion"
            type="text "
            [(ngModel)]="opinionDataService.opinionFODTO.opinionSuggestion"
          ></textarea>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col text-center">
          <button class="btn btn-success mt-1" type="submit" (click)="submit()" [disabled]="loading">
            {{ 'opinion-question.list.Envoyer' | translate }}
            @if (loading) {
              <i class="fa-light fa-spinner spinning ms-2"></i>
            }
          </button>
        </div>
      </div>
    </div>
  </div>
}
