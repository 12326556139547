import { Component, inject, input } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { AuthService, HijiBundle, ModulesDTO, UserActionsDTO } from 'common';

@Component({
    selector: 'hiji-user-actions-campaign180',
    templateUrl: './user-actions-campaign180.component.html',
    styleUrls: ['./user-actions-campaign180.component.scss'],
    standalone: false
})
export class UserActionsCampaign180Component {
  private authService = inject(AuthService);

  readonly data = input<UserActionsDTO>(undefined);
  readonly me = input<boolean>(false);
  string_to_slug = string_to_slug;
  modulesDTO: ModulesDTO;

  constructor() {
    this.modulesDTO = (<HijiBundle>this.authService.userBundle.data).modules;
  }
}
