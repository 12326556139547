<div class="bg-white bg-light-30p-hover border rounded-3">
  <div class="row g-1">
    <div class="col-auto d-flex align-items-center">
      <i class="fa-light fa-poll-h fa-2x text-tertiary p-4"></i>
    </div>
    <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
      <div class="row">
        <div class="col-auto">
          {{ 'visualize.share-card.Restitution du' | translate }}
          <b>{{ bilan().comiteDate | localizedDate }}</b>
        </div>
      </div>
    </div>
  </div>
</div>
