import { Component, inject, input } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { AuthService, PDIDTO, UserActionsDTO } from 'common';

@Component({
    selector: 'hiji-user-actions-pdi',
    templateUrl: './user-actions-pdi.component.html',
    styleUrls: ['./user-actions-pdi.component.scss'],
    standalone: false
})
export class UserActionsPdiComponent {
  private authService = inject(AuthService);

  readonly data = input<UserActionsDTO>(undefined);
  string_to_slug = string_to_slug;
  idUserLogged: number;

  constructor() {
    this.idUserLogged = this.authService.userBundle.user.idUser;
  }

  verifWarningPDIEndDate(pdi: PDIDTO) {
    const newDate = new Date(new Date().setDate(new Date().getDate() + 3));
    return newDate >= pdi.endDate;
  }
}
