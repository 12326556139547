import { Component, OnInit, inject, input } from '@angular/core';
import { string_to_slug } from '@ic2/ic2-lib';
import { AuthService, HijiBundle, TeamMemberDTO, VizSurveyDTO } from 'common';

@Component({
    selector: 'hiji-visualize-survey-card',
    templateUrl: './visualize-survey-card.component.html',
    styleUrls: ['./visualize-survey-card.component.scss'],
    standalone: false
})
export class VisualizeSurveyCardComponent implements OnInit {
  authService = inject(AuthService);

  readonly user = input<TeamMemberDTO>(undefined);
  string_to_slug = string_to_slug;
  readonly survey = input<VizSurveyDTO>(undefined);
  avatarExtension;
  constructor() {
    this.avatarExtension = (<HijiBundle>this.authService.userBundle.data).hijiUser.avatarExtension;
  }

  ngOnInit(): void {}
}
