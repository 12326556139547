import { Component, inject, input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EMAObjectiveFormula, EMAObjectiveResult, KpiDTO, LocalizedDatePipe } from 'common';

@Component({
  selector: 'hiji-user-actions-kpi',
  templateUrl: './user-actions-kpi.component.html',
  styleUrls: ['./user-actions-kpi.component.scss'],
  standalone: false,
})
export class UserActionsKpiComponent {
  private localizedDatePipe = inject(LocalizedDatePipe);
  private translate = inject(TranslateService);

  readonly data = input<KpiDTO[]>(undefined);
  showHisto: boolean = false;
  lastKPI: any;

  toggleHisto() {
    this.showHisto = !this.showHisto;
  }

  getResults(kpi: KpiDTO) {
    return kpi.results.filter((result) => !result.monthly);
  }

  getLastResultValue(kpi: KpiDTO): number {
    const res = this.getLastResult(kpi);
    if (kpi.objective.showAsPercentageInGraph && res.objective !== 0 && res.objective !== null) {
      if (kpi.objective.formula === EMAObjectiveFormula.RO) return (res.result / res.objective) * 100;
      else if (kpi.objective.formula === EMAObjectiveFormula.OR) return (res.objective / res.result) * 100;
      else throw new Error('Invalid formula');
    }
    return res.result;
  }

  getLastResultTooltip(kpi: KpiDTO): string {
    const res = this.getLastResult(kpi);
    if (kpi.objective.showAsPercentageInGraph) {
      if (kpi.objective.formula === EMAObjectiveFormula.RO) return res.result + ' / ' + res.objective;
      else if (kpi.objective.formula === EMAObjectiveFormula.OR) return res.objective + ' / ' + res.result;
      else throw new Error('Invalid formula');
    }
    return null;
  }

  getLastResult(kpi: KpiDTO): EMAObjectiveResult {
    const results = this.getResults(kpi);
    return results[results.length - 1];
  }

  isObjectiveReached(kpi: KpiDTO): boolean {
    const res = this.getLastResult(kpi);
    if (res.objective === null) return false;
    if (kpi.objective.formula === EMAObjectiveFormula.RO) return res.result >= res.objective;
    else if (kpi.objective.formula === EMAObjectiveFormula.OR) return res.result <= res.objective;
    else throw new Error('Invalid formula');
  }

  getInfos(kpi: KpiDTO) {
    const results = this.getResults(kpi);
    if (results.length >= 2) {
      return this.translate.instant('user-actions.kpi.{{result1}} au {{date1}} comparé à {{result2}} au {{date2}}', {
        date1: this.localizedDatePipe.transform(results[results.length - 1].creationDate),
        result1: results[results.length - 1].result,
        date2: this.localizedDatePipe.transform(results[results.length - 2].creationDate),
        result2: results[results.length - 2].result,
      });
    } else if (results.length === 1) {
      return this.translate.instant('user-actions.kpi.{{result1}} au {{date1}}', {
        date1: this.localizedDatePipe.transform(results[results.length - 1].creationDate),
        result1: results[results.length - 1].result,
      });
    }
    return '';
  }

  getDate(kpi: KpiDTO) {
    const results = this.getResults(kpi);
    if (results.length >= 1) {
      return this.localizedDatePipe.transform(results[results.length - 1].creationDate);
    }
    return '';
  }

  getTendance(kpi: KpiDTO): 'up' | 'down' | 'flat' {
    const results = this.getResults(kpi);
    if (results.length < 2) {
      return 'flat';
    }
    const lastResult = results[results.length - 1];
    const previousResult = results[results.length - 2];

    //TODO il faudrait probablement calculer la tendance par rapport à un résultat / objectif et donc comparer des % et non des valeurs absolues (dans le cas ou l'objectif != 0 && != null)
    // il faut surement ajouter un bool a l'objectif si c'est comme ça qu'il doit se comporter
    if (lastResult.result > previousResult.result) return 'up';
    else if (lastResult.result < previousResult.result) return 'down';
    else return 'flat';
  }
}
