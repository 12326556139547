import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L, string_to_slug } from '@ic2/ic2-lib';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  DateUtils,
  HijiBundle,
  HomeDTO,
  HomeService,
  Ic2ToastrService,
  ModulesDTO,
  TeamMemberDTO,
  UserActionsDTO,
  UserActionsService,
  UserBundle,
} from 'common';

@Component({
    selector: 'hiji-my-coaching',
    templateUrl: './my-coaching.component.html',
    styleUrl: './my-coaching.component.scss',
    standalone: false
})
export class MyCoachingComponent implements OnInit {
  private userActionsService = inject(UserActionsService);
  private authService = inject(AuthService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private ic2ToastrService = inject(Ic2ToastrService);
  private translate = inject(TranslateService);
  private homeService = inject(HomeService);

  modulesDTO: ModulesDTO;
  idUser: number;
  userActionsDTO: UserActionsDTO;
  loading: boolean = true;
  loadingHomeData: boolean = true;
  me: boolean = false;
  hideVisualize: boolean = false;
  userLogged: UserBundle;
  homeDTO: HomeDTO = null;
  string_to_slug = string_to_slug;
  userMember: TeamMemberDTO = null;
  manualHierarchicalManagement: boolean = false;
  DateUtils: typeof DateUtils = DateUtils;

  constructor() {
    const authService = this.authService;

    this.modulesDTO = (this.authService.userBundle.data as HijiBundle).modules;
    this.userLogged = authService.userBundle;
    this.manualHierarchicalManagement = this.authService.modules.moduleGlobal.manualHierarchicalManagement;
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadUserActionsDataAndUserMember();
  }

  private loadUserActionsDataAndUserMember() {
    this.route.params.subscribe((params) => {
      this.loading = true;
      this.idUser = +params['idUser'];
      this.loadHomeData();

      if (this.idUser === this.authService.userBundle.user.idUser) this.me = true;
      else this.me = false;

      this.userActionsService.getActions(this.idUser).subscribe(
        (data) => {
          this.userActionsDTO = data;
          this.loading = false;
        },
        (error) => {
          if (error.isBusinessError() && error.data && error.data['code'] === 'no_common_enseigne')
            this.ic2ToastrService.show(
              this.translate.instant("user-actions.Vous n'appartenez pas à une enseigne commune avec cet utilisateur, contactez l'assistance"),
              {
                classname: 'bg-warning text-white',
              }
            );
          L.e(error);
          this.router.navigate(['/']);
          this.loading = false;
        }
      );
    });
  }

  private loadHomeData(): Promise<HomeDTO> {
    this.loadingHomeData = true;
    return new Promise((resolve, reject) => {
      this.homeService
        .getTasks()
        .defaultOnErrorAnd(() => {
          resolve(null);
        })
        .execute((data) => {
          this.homeDTO = data;
          if (this.idUser !== 0 && !this.me) this.userMember = this.homeDTO.team.find((u) => u.idUser === this.idUser);
          else this.userMember = data.me;

          this.loadingHomeData = false;
          resolve(this.homeDTO);
        });
    });
  }
}
