<div class="bg-white bg-light-30p-hover border rounded-3 pointer">
  <div class="row g-1">
    <div class="col-auto d-flex align-items-center">
      <i class="fa-light fa-poll fa-2x text-tertiary p-4"></i>
    </div>
    <div class="col my-fs-2 my-fs-xxl-3 d-flex flex-column justify-content-center">
      <div class="row">
        <div class="col-auto">
          <b>{{ survey().name | ms }}</b>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <small>{{ 'survey.card.Fin le' | translate }} {{ survey().endDate | localizedDate }}</small>
        </div>
      </div>
    </div>
  </div>
</div>
