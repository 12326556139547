import { Component, inject, OnInit, signal } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { EnvService, MultilingualString } from '@ic2/ic2-lib';
import { Config, RadarData, TagRadarData } from 'common';
class testDTO {
  a: string;
}
@Component({
  selector: 'hiji-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  standalone: false,
})
export class TestComponent implements OnInit {
  private swPush = inject(SwPush);
  private env = inject<EnvService<Config>>(EnvService);

  dataForRadarChart: RadarData[] = [];
  dataForTagRadarChart: TagRadarData[] = [];

  testSignal = signal(new testDTO());
  show = signal(false);

  test() {
    console.log(this.testSignal());
  }
  constructor() {
    //mock data
    this.dataForRadarChart = [
      {
        name: 'blue',
        color: 'blue',
        background: true,
        values: [
          { axis: 'A', value: 70, color: '#f1b314', data: 1 },
          { axis: 'B', value: 70, color: '#f1b314', data: 2 },
          { axis: 'C', value: 70, color: '#f1b314', data: 3 },
          { axis: 'D', value: 70, color: 'cyan', data: 4 },
        ],
      },
      {
        name: 'red',
        color: 'red',
        background: true,
        values: [
          { axis: 'A', value: 50, color: '#f1b314', data: 1 },
          { axis: 'B', value: 50, color: '#f1b314', data: 2 },
          { axis: 'C', value: 50, color: 'cyan', data: 4 },
        ],
      },
    ];

    this.dataForTagRadarChart = [
      {
        name: 'blue',
        color: 'blue',
        background: true,
        values: [
          {
            axis: new MultilingualString({ en: '1 en', fr: '4 fr', es: 'Comunicación' }),
            value: 70,
            color: '#f1b314',
            data: 1,
          },
          {
            axis: new MultilingualString({ en: '2 en', fr: '3 fr', es: 'Trabajo en equipo' }),
            value: 70,
            color: '#f1b314',
            data: 2,
          },
          {
            axis: new MultilingualString({ en: '3 en', fr: '2 fr', es: 'Liderazgo' }),
            value: 70,
            color: '#f1b314',
            data: 3,
          },
          {
            axis: new MultilingualString({ en: '4 en', fr: '1 fr', es: 'Resolución de problemas' }),
            value: 70,
            color: 'cyan',
            data: 4,
          },
        ],
      },
    ];
  }

  ngOnInit(): void {}

  testBadge() {
    if ('setAppBadge' in navigator) {
      (<any>navigator).setAppBadge(2).catch((error) => {
        console.log('err setAppBadge', error);
      });
    }
  }

  testRegister() {
    this.swPush
      .requestSubscription({
        serverPublicKey: this.env.configuration.vapidPublicKey,
      })
      .then((sub) => console.log(sub.toJSON()))
      .catch((err) => console.error('Could not subscribe to notifications', err));
  }
}
